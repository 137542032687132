import React from 'react';
import logosynergia from '../assets/images/synergiaconsultingbl.svg';
import { IoMailOpenOutline } from "react-icons/io5";
import { FaFacebook } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";

const Footer = () => {
  return (
    <footer className="bg-[#7A121A] py-12">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-sm font-semibold text-white tracking-wider uppercase">Company</h3>
            <ul className="mt-4 space-y-4">
              <li><a href="#/" className="text-base text-gray-300 hover:text-white">¿Quiénes somos?</a></li>
              <li><a href="#/" className="text-base text-gray-300 hover:text-white">Aviso legal y privacidad</a></li>
              <li><a href="#/" className="text-base text-gray-300 hover:text-white">Protección de Datos</a></li>
              <li><a href="#/" className="text-base text-gray-300 hover:text-white">Preguntas Frecuentes</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-sm font-semibold text-white tracking-wider uppercase">Support</h3>
            <ul className="mt-4 space-y-4">
              <li><a href="#/" className="text-base text-gray-300 hover:text-white">Cómo aplicar</a></li>
              <li><a href="#/" className="text-base text-gray-300 hover:text-white">Buscar Empleo</a></li>
              <li><a href="#/" className="text-base text-gray-300 hover:text-white">Restaurar Contraseña</a></li>
              <li><a href="#/" className="text-base text-gray-300 hover:text-white">Mi Cuenta</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-sm font-semibold text-white tracking-wider uppercase">Services</h3>
            <ul className="mt-4 space-y-4">
              <li><a href="#/" className="text-base text-gray-300 hover:text-white">Talento Humano</a></li>
              <li><a href="#/" className="text-base text-gray-300 hover:text-white">Gestión Legal</a></li>
              <li><a href="#/" className="text-base text-gray-300 hover:text-white">Producción y Logística</a></li>
              <li><a href="#/" className="text-base text-gray-300 hover:text-white">Financiera y Contable</a></li>
            </ul>
          </div>
          <div className="flex items-center justify-center md:justify-end">
            <img src={logosynergia} alt="Synergia Logo" className="h-16" />
          </div>
        </div>
        <div className="mt-12 border-t border-gray-700 pt-8 flex justify-between items-center">
          <p className="text-base text-gray-400">
            © 2024 Synergia Consulting | Desarrollado por <a href="https://www.kuvdigital.com" className="hover:text-white" target="_blank" rel="noopener noreferrer">KUV Digital</a>
          </p>
          <div className="flex space-x-6">
            <a href="#/" className="text-gray-400 hover:text-white">
              <FaFacebook className="h-6 w-6" />
            </a>
            <a href="#/" className="text-gray-400 hover:text-white">
              <RiInstagramFill className="h-6 w-6" />
            </a>
            <a href="#/" className="text-gray-400 hover:text-white">
              <IoMailOpenOutline className="h-6 w-6" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
