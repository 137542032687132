import React from 'react';

const BeneficiosEmpresa = ({ beneficiosEmpresa }) => (
  <>
    {beneficiosEmpresa.beneficios_empresa_text && (
      <div className="mb-4">
        <h4 className="font-bold text-[#7A121A]">Beneficios de la Empresa:</h4>
        <div dangerouslySetInnerHTML={{ __html: beneficiosEmpresa.beneficios_empresa_text }}></div>
      </div>
    )}
    {beneficiosEmpresa.beneficios_empresa_lista.length > 0 && (
      <div className="mb-4">
        <h4 className="font-bold text-[#7A121A]">Lista de Beneficios:</h4>
        <ul className="list-disc list-inside">
          {beneficiosEmpresa.beneficios_empresa_lista.map((beneficio, index) => (
            <li key={index}>{beneficio}</li>
          ))}
        </ul>
      </div>
    )}
  </>
);

export default BeneficiosEmpresa;
