import React from 'react';
import HeroSlider from '../components/web/HeroSlider';
import NuestrosProductos from '../components/web/NuestrosProductos'; 
import GestionLegal from '../components/web/GestionLegal';
import GestionProduccionLogistica from '../components/web/GestionProduccionLogistica';
import GestionFinancieraContable from '../components/web/GestionFinancieraContable';
import QuienesSomos from '../components/web/QuienesSomos';
import TalentoHumano from '../components/web/TalentoHumano';
import VisionMision from '../components/web/VisionMision';
import Contactos from '../components/web/Contactos';

const Home = () => {
  return (
    <div>
      <HeroSlider />
      <NuestrosProductos />
      <GestionLegal />
      <GestionProduccionLogistica />
      <QuienesSomos />
      <GestionFinancieraContable />
      <TalentoHumano />
      <VisionMision />
      <Contactos />
    </div>
  );
};

export default Home;
