import React from 'react';

const NuestrosProductos = () => {
  return (
    <div className="bg-white py-16 sm:py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-[#7A121A] tracking-wide uppercase">Nuestros Productos</p>
          <h2 className="mt-1 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Soluciones Integrales para tu Empresa
          </h2>
          <p className="max-w-2xl mt-5 mx-auto text-xl text-gray-500 text-justify lg:text-center">
            Descubre nuestros servicios en asesoría contable y financiera, gestión legal, y logística empresarial.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NuestrosProductos;
