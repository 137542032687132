import React from 'react';

const TareasPuesto = ({ tareasPuesto }) => (
  <>
    {tareasPuesto.tareas_puesto_text && (
      <div className="mb-4">
        <h4 className="font-bold text-[#7A121A]">Tareas del Puesto:</h4>
        <div dangerouslySetInnerHTML={{ __html: tareasPuesto.tareas_puesto_text }}></div>
      </div>
    )}
    {tareasPuesto.tareas_puesto_lista.length > 0 && (
      <div className="mb-4">
        <h4 className="font-bold text-[#7A121A]">Lista de Tareas:</h4>
        <ul className="list-disc list-inside">
          {tareasPuesto.tareas_puesto_lista.map((tarea, index) => (
            <li key={index}>{tarea}</li>
          ))}
        </ul>
      </div>
    )}
  </>
);

export default TareasPuesto;
