import React from 'react';
import { CgEditBlackPoint } from 'react-icons/cg';
import 'animate.css';
import imagenLegal from '../../assets/images/Gestion-Integral.bk_.jpg';

const GestionLegal = () => {
  return (
    <div className="flex flex-col md:flex-row items-center bg-[#7A121A] overflow-hidden min-h-[553px] w-full">
      <div className="w-full md:w-1/2 h-full">
        <img
          className="w-full h-full object-cover"
          src={imagenLegal}
          alt="Gestión Legal"
        />
      </div>
      <div className="w-full md:w-1/2 h-full flex items-center justify-center p-0 bg-[#7A121A]">
        <div className="max-w-xl p-6">
          <h2 className="text-base font-semibold text-white tracking-wide uppercase animate__animated animate__fadeInDown">Servicios Jurídicos</h2>
          <h1 className="text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl mt-2 animate__animated animate__fadeInLeft animate__delay-1s">
            Gestión Legal
          </h1>
          <p className="mt-4 text-xl text-white animate__animated animate__fadeInLeft animate__delay-2s text-justify">
            Ofrecemos asesoría y servicios legales completos para satisfacer las necesidades de su empresa.
          </p>
          <div className="mt-6 space-y-4">
            <div className="flex items-start animate__animated animate__fadeInUp animate__delay-3s">
              <CgEditBlackPoint className="flex-shrink-0 h-6 w-6 text-white" aria-hidden="true" />
              <div className="ml-3 text-base text-white">
                <span className="font-bold text-white">Asesoría en Legislación Laboral.</span>
              </div>
            </div>
            <div className="flex items-start animate__animated animate__fadeInUp animate__delay-3.5s">
              <CgEditBlackPoint className="flex-shrink-0 h-6 w-6 text-white" aria-hidden="true" />
              <div className="ml-3 text-base text-white">
                <span className="font-bold text-white">Elaboración de contratos de trabajo.</span>
              </div>
            </div>
            <div className="flex items-start animate__animated animate__fadeInUp animate__delay-4s">
              <CgEditBlackPoint className="flex-shrink-0 h-6 w-6 text-white" aria-hidden="true" />
              <div className="ml-3 text-base text-white">
                <span className="font-bold text-white">Procedimientos disciplinarios, conciliaciones.</span>
              </div>
            </div>
            <div className="flex items-start animate__animated animate__fadeInUp animate__delay-4.5s">
              <CgEditBlackPoint className="flex-shrink-0 h-6 w-6 text-white" aria-hidden="true" />
              <div className="ml-3 text-base text-white">
                <span className="font-bold text-white">Auditoría o Diagnóstico Laboral.</span>
              </div>
            </div>
            <div className="flex items-start animate__animated animate__fadeInUp animate__delay-5s">
              <CgEditBlackPoint className="flex-shrink-0 h-6 w-6 text-white" aria-hidden="true" />
              <div className="ml-3 text-base text-white">
                <span className="font-bold text-white">Elaboración de Políticas Internas.</span>
              </div>
            </div>
            <div className="flex items-start animate__animated animate__fadeInUp animate__delay-5.5s">
              <CgEditBlackPoint className="flex-shrink-0 h-6 w-6 text-white" aria-hidden="true" />
              <div className="ml-3 text-base text-white">
                <span className="font-bold text-white">Servicios Notariales.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestionLegal;
