import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axiosInstance from '../components/WooCommerce/axiosConfig';
import HeroVacantes from '../components/WooCommerce/HeroVacantes';
import ProductCard from '../components/WooCommerce/ProductCar';
import ProductoDetails from '../components/WooCommerce/ProductDetail';

const Busquedas = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const jobCategory = searchParams.get('jobCategory');
    const provinceCategory = searchParams.get('provinceCategory');

    const fetchProducts = async () => {
      try {
        const { data } = await axiosInstance.get('/products', {
          params: {
            category: `${jobCategory},${provinceCategory}`,
          },
        });
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [location.search]);

  return (
    <>
      <HeroVacantes />
      <div className="container mx-auto p-4">
        <h2 className="text-2xl font-bold mb-4">Resultados de la búsqueda</h2>
        <p className="mb-4">Aplica a las mejores ofertas de trabajo de Costa Rica. Verifica detalladamente los requisitos para cada puesto y encuentra tu nuevo trabajo.</p>
        {!loading && (
          <div className="mb-4">
            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-[#7A121A] text-white">
              {products.length} Resultados encontrados
            </span>
          </div>
        )}
        {loading ? (
          <p>Cargando...</p>
        ) : products.length > 0 ? (
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col space-y-4">
              {products.map(product => (
                <ProductCard 
                  key={product.id} 
                  product={product} 
                  onClick={setSelectedProduct}
                  isSelected={selectedProduct?.id === product.id}
                />
              ))}
            </div>
            <div className="col-span-1">
              <ProductoDetails product={selectedProduct} />
            </div>
          </div>
        ) : (
          <div className="text-center">
            <h3 className="text-xl font-bold mb-2">SIN RESULTADOS</h3>
            <p>Parece que no hemos encontrado coincidencias con tu búsqueda. Puedes intentar buscar otras opciones o te recomendamos otras ofertas que puedan ser interesantes.</p>
          </div>
        )}
      </div>
    </>
  );
};

export default Busquedas;
