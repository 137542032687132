import React from 'react';

const DescripcionProducto = ({ short_description, description }) => (
  <>
    <div className="mb-4" dangerouslySetInnerHTML={{ __html: short_description }}></div>
    <div className="mb-4" dangerouslySetInnerHTML={{ __html: description }}></div>
  </>
);

export default DescripcionProducto;
