import React from 'react';
import heroseccion from '../../assets/images/heroslider.webp';
import logosynergia from '../../assets/images/synergiaconsultingcr.svg';

const HeroSlider = () => {
  return (
    <div className="relative max-h-[71vh]">
      <img
        src={heroseccion}
        alt="Hero Section"
        className="w-full h-full max-h-[71vh] object-cover object-center"
        style={{ backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
      />
      <div className="absolute inset-0 bg-[#7A121A] bg-opacity-50 flex items-center justify-center">
        <img src={logosynergia} alt="Synergia Logo" className="h-16 md:h-24 lg:h-32" />
      </div>
    </div>
  );
};

export default HeroSlider;
