import React from 'react';
import synergiaSvg from '../../assets/images/LogoRojo.svg'; // Asegúrate de que este archivo SVG exista y esté en la ruta correcta
import { FaCheckCircle } from 'react-icons/fa';

const AyudaEmpleo = () => {
  return (
    <div className="bg-white py-16 sm:py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center">
        <div className="md:w-2/5 mb-8 md:mb-0 flex justify-center">
          <img src={synergiaSvg} alt="Synergia Consulting" className="w-2/5" />
        </div>
        <div className="md:w-3/5 md:pl-12">
          <h2 className="text-3xl font-extrabold text-[#7A121A] mb-4">En Synergia Consulting, te conectamos con las mejores oportunidades para tu próximo empleo.</h2>
          <p className="text-xl text-gray-500 mb-6">Ofertas de Empleo en Costa Rica</p>
          <h3 className="text-2xl font-semibold text-gray-900 mb-4">Encuentra oportunidades laborales que se adapten a ti con nuestra ayuda.</h3>
          <p className="text-lg text-gray-500 mb-6">Destaca tu perfil profesional y conéctate con miles de empresas en nuestra bolsa de trabajo.</p>
          <ul className="space-y-2">
            <li className="flex items-center">
              <FaCheckCircle className="text-green-500 mr-2" />
              <span className="text-lg text-gray-700">Regístrate gratis y comienza tu búsqueda de empleo hoy mismo</span>
            </li>
            <li className="flex items-center">
              <FaCheckCircle className="text-green-500 mr-2" />
              <span className="text-lg text-gray-700">Ofertas diarias. Empleos que se adaptan a tu perfil</span>
            </li>
            <li className="flex items-center">
              <FaCheckCircle className="text-green-500 mr-2" />
              <span className="text-lg text-gray-700">Configura alertas personalizadas de empleo y te notificaremos sobre nuevas oportunidades.</span>
            </li>
            <li className="flex items-center">
              <FaCheckCircle className="text-green-500 mr-2" />
              <span className="text-lg text-gray-700">Completa tu perfil y destaca como un profesional para ganar visibilidad.</span>
            </li>
          </ul>
          <button className="mt-8 bg-[#7A121A] text-white font-bold py-3 px-6 rounded-lg hover:bg-[#b91c1c]">
            Crea tu cuenta gratis
          </button>
        </div>
      </div>
    </div>
  );
};

export default AyudaEmpleo;
