import axios from 'axios';

const consumerKey = 'ck_349cdb434c89bc450867f9b48c9d02973a01cbf6';
const consumerSecret = 'cs_ba266c12d71a58c88a553cbdaf022e922ce484ae';

const axiosInstance = axios.create({
  baseURL: 'https://api.synergiaconsultingcr.com/wp-json/wc/v3',
  auth: {
    username: consumerKey,
    password: consumerSecret,
  },
});

export const getSubcategories = async (parentCategorySlug) => {
  try {
    const { data } = await axiosInstance.get('/products/categories', {
      params: {
        slug: parentCategorySlug,
      },
    });
    if (data && data.length > 0) {
      const parentCategoryId = data[0].id;
      const subcategories = await axiosInstance.get('/products/categories', {
        params: {
          parent: parentCategoryId,
        },
      });
      return subcategories.data;
    }
    return [];
  } catch (error) {
    console.error('Error fetching subcategories:', error);
    return [];
  }
};

export const getObjetivoPuesto = async (productId) => {
  try {
    const { data } = await axiosInstance.get(`/products/${productId}`);
    return data.meta_data.find(meta => meta.key === 'objetivo_puesto_text')?.value || '';
  } catch (error) {
    console.error('Error fetching objetivo_puesto_text:', error);
    return '';
  }
};

export const updateObjetivoPuesto = async (productId, objetivoPuestoText) => {
  try {
    const { data } = await axiosInstance.put(`/products/${productId}`, {
      meta_data: [
        {
          key: 'objetivo_puesto_text',
          value: objetivoPuestoText,
        },
      ],
    });
    return data;
  } catch (error) {
    console.error('Error updating objetivo_puesto_text:', error);
    throw error;
  }
};

export const getRequisitos = async (productId) => {
  try {
    const { data } = await axiosInstance.get(`/products/${productId}`);
    return {
      requisitos_text: data.meta_data.find(meta => meta.key === 'requisitos_text')?.value || '',
      requisitos_lista: data.meta_data.find(meta => meta.key === 'requisitos_lista')?.value || [],
    };
  } catch (error) {
    console.error('Error fetching requisitos:', error);
    return { requisitos_text: '', requisitos_lista: [] };
  }
};

export const updateRequisitos = async (productId, requisitosText, requisitosLista) => {
  try {
    const { data } = await axiosInstance.put(`/products/${productId}`, {
      meta_data: [
        {
          key: 'requisitos_text',
          value: requisitosText,
        },
        {
          key: 'requisitos_lista',
          value: requisitosLista,
        },
      ],
    });
    return data;
  } catch (error) {
    console.error('Error updating requisitos:', error);
    throw error;
  }
};

export const getTareasPuesto = async (productId) => {
  try {
    const { data } = await axiosInstance.get(`/products/${productId}`);
    return {
      tareas_puesto_text: data.meta_data.find(meta => meta.key === 'tareas_puesto_text')?.value || '',
      tareas_puesto_lista: data.meta_data.find(meta => meta.key === 'tareas_puesto_lista')?.value || [],
    };
  } catch (error) {
    console.error('Error fetching tareas_puesto:', error);
    return { tareas_puesto_text: '', tareas_puesto_lista: [] };
  }
};

export const updateTareasPuesto = async (productId, tareasPuestoText, tareasPuestoLista) => {
  try {
    const { data } = await axiosInstance.put(`/products/${productId}`, {
      meta_data: [
        {
          key: 'tareas_puesto_text',
          value: tareasPuestoText,
        },
        {
          key: 'tareas_puesto_lista',
          value: tareasPuestoLista,
        },
      ],
    });
    return data;
  } catch (error) {
    console.error('Error updating tareas_puesto:', error);
    throw error;
  }
};

export const getRequisitosObligatorios = async (productId) => {
  try {
    const { data } = await axiosInstance.get(`/products/${productId}`);
    return {
      requisitos_obligatorios_text: data.meta_data.find(meta => meta.key === 'requisitos_obligatorios_text')?.value || '',
      requisitos_obligatorios_lista: data.meta_data.find(meta => meta.key === 'requisitos_obligatorios_lista')?.value || [],
    };
  } catch (error) {
    console.error('Error fetching requisitos_obligatorios:', error);
    return { requisitos_obligatorios_text: '', requisitos_obligatorios_lista: [] };
  }
};

export const updateRequisitosObligatorios = async (productId, requisitosObligatoriosText, requisitosObligatoriosLista) => {
  try {
    const { data } = await axiosInstance.put(`/products/${productId}`, {
      meta_data: [
        {
          key: 'requisitos_obligatorios_text',
          value: requisitosObligatoriosText,
        },
        {
          key: 'requisitos_obligatorios_lista',
          value: requisitosObligatoriosLista,
        },
      ],
    });
    return data;
  } catch (error) {
    console.error('Error updating requisitos_obligatorios:', error);
    throw error;
  }
};

export const getBeneficiosEmpresa = async (productId) => {
  try {
    const { data } = await axiosInstance.get(`/products/${productId}`);
    return {
      beneficios_empresa_text: data.meta_data.find(meta => meta.key === 'beneficios_empresa_text')?.value || '',
      beneficios_empresa_lista: data.meta_data.find(meta => meta.key === 'beneficios_empresa_lista')?.value || [],
    };
  } catch (error) {
    console.error('Error fetching beneficios_empresa:', error);
    return { beneficios_empresa_text: '', beneficios_empresa_lista: [] };
  }
};

export const updateBeneficiosEmpresa = async (productId, beneficiosEmpresaText, beneficiosEmpresaLista) => {
  try {
    const { data } = await axiosInstance.put(`/products/${productId}`, {
      meta_data: [
        {
          key: 'beneficios_empresa_text',
          value: beneficiosEmpresaText,
        },
        {
          key: 'beneficios_empresa_lista',
          value: beneficiosEmpresaLista,
        },
      ],
    });
    return data;
  } catch (error) {
    console.error('Error updating beneficios_empresa:', error);
    throw error;
  }
};

export default axiosInstance;
