import React, { useEffect, useState } from 'react';
import { getObjetivoPuesto, getRequisitos, getTareasPuesto, getRequisitosObligatorios, getBeneficiosEmpresa } from './axiosConfig';
import HeaderProducto from './HeaderProducto';
import DescripcionProducto from './DescripcionProducto';
import ObjetivoPuesto from './ObjetivoPuesto';
import Requisitos from './Requisitos';
import TareasPuesto from './TareasPuesto';
import RequisitosObligatorios from './RequisitosObligatorios';
import BeneficiosEmpresa from './BeneficiosEmpresa';

const ProductoDetails = ({ product }) => {
  const [objetivoPuesto, setObjetivoPuesto] = useState('');
  const [requisitos, setRequisitos] = useState({ requisitos_text: '', requisitos_lista: [] });
  const [tareasPuesto, setTareasPuesto] = useState({ tareas_puesto_text: '', tareas_puesto_lista: [] });
  const [requisitosObligatorios, setRequisitosObligatorios] = useState({ requisitos_obligatorios_text: '', requisitos_obligatorios_lista: [] });
  const [beneficiosEmpresa, setBeneficiosEmpresa] = useState({ beneficios_empresa_text: '', beneficios_empresa_lista: [] });

  useEffect(() => {
    if (product) {
      const fetchObjetivoPuesto = async () => {
        try {
          const objetivo = await getObjetivoPuesto(product.id);
          setObjetivoPuesto(objetivo);
        } catch (error) {
          console.error('Error fetching objetivo_puesto_text:', error);
        }
      };

      const fetchRequisitos = async () => {
        try {
          const req = await getRequisitos(product.id);
          setRequisitos(req);
        } catch (error) {
          console.error('Error fetching requisitos:', error);
        }
      };

      const fetchTareasPuesto = async () => {
        try {
          const tareas = await getTareasPuesto(product.id);
          setTareasPuesto(tareas);
        } catch (error) {
          console.error('Error fetching tareas_puesto:', error);
        }
      };

      const fetchRequisitosObligatorios = async () => {
        try {
          const requisitosObligatorios = await getRequisitosObligatorios(product.id);
          setRequisitosObligatorios(requisitosObligatorios);
        } catch (error) {
          console.error('Error fetching requisitos_obligatorios:', error);
        }
      };

      const fetchBeneficiosEmpresa = async () => {
        try {
          const beneficios = await getBeneficiosEmpresa(product.id);
          setBeneficiosEmpresa(beneficios);
        } catch (error) {
          console.error('Error fetching beneficios_empresa:', error);
        }
      };

      fetchObjetivoPuesto();
      fetchRequisitos();
      fetchTareasPuesto();
      fetchRequisitosObligatorios();
      fetchBeneficiosEmpresa();
    }
  }, [product]);

  if (!product) {
    return <p className="text-gray-500">Selecciona una oferta para ver los detalles.</p>;
  }

  const puestoTrabajoCategories = product.categories.filter(cat => cat.slug === 'puesto-trabajo');
  const subCategories = product.categories.filter(cat => cat.parent === puestoTrabajoCategories[0]?.id);

  return (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <HeaderProducto product={product} />
      <DescripcionProducto short_description={product.short_description} description={product.description} />
      
      {subCategories.length > 0 && (
        <div className="mb-4">
          <h4 className="font-bold text-[#7A121A]">Categorías:</h4>
          <div className="flex flex-wrap">
            {subCategories.map((subCat, index) => (
              <span key={index} className="bg-[#7A121A] text-white px-2 py-1 rounded mr-2 mb-2">{subCat.name}</span>
            ))}
          </div>
        </div>
      )}
      
      <ObjetivoPuesto objetivoPuesto={objetivoPuesto} />
      <Requisitos requisitos={requisitos} />
      <TareasPuesto tareasPuesto={tareasPuesto} />
      <RequisitosObligatorios requisitosObligatorios={requisitosObligatorios} />
      <BeneficiosEmpresa beneficiosEmpresa={beneficiosEmpresa} />
    </div>
  );
};

export default ProductoDetails;
