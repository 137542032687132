import React from 'react';
import HeroVacantes from '../components/WooCommerce/HeroVacantes';
import ProductList from '../components/WooCommerce/AyudaEmpleo';

const Vacantes = () => {
  return (
    <div>
      <HeroVacantes />
      <ProductList />
    </div>
  );
};

export default Vacantes;
