import React, { useState, useEffect } from 'react';
import { getSubcategories } from './axiosConfig';
import backgroundImg from '../../assets/images/Gestion-Financiera.jpg';
import { MdOutlineWorkOutline } from "react-icons/md";
import { PiMapPinAreaLight } from "react-icons/pi";
import { useNavigate } from 'react-router-dom';

const HeroVacantes = () => {
  const [jobCategories, setJobCategories] = useState([]);
  const [provinceCategories, setProvinceCategories] = useState([]);
  const [selectedJobCategory, setSelectedJobCategory] = useState('');
  const [selectedProvinceCategory, setSelectedProvinceCategory] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const jobSubcategories = await getSubcategories('puesto-trabajo');
        setJobCategories(jobSubcategories);
      } catch (error) {
        console.error("Error fetching job subcategories:", error);
      }

      try {
        const provinceSubcategories = await getSubcategories('provincias');
        setProvinceCategories(provinceSubcategories);
      } catch (error) {
        console.error("Error fetching province subcategories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleSearch = () => {
    navigate(`/busquedas?jobCategory=${selectedJobCategory}&provinceCategory=${selectedProvinceCategory}`);
  };

  return (
    <div
      className="relative h-96 flex flex-col items-center justify-center"
      style={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="absolute inset-0 bg-[#7A121A] opacity-70"></div>
      <div className="relative z-10 text-center px-4">
        <h1 className="text-3xl font-bold text-white">
          DESCUBRE TU PRÓXIMO DESAFÍO PROFESIONAL
        </h1>
        <p className="mt-2 text-xl text-white">
          ¡INSCRÍBETE HOY Y ACELERA TU CARRERA PROFESIONAL!
        </p>
        <div className="mt-6 bg-white rounded-lg shadow-lg p-4 flex flex-col sm:flex-row items-center justify-between w-full max-w-3xl">
          <div className="relative flex-1 mb-2 sm:mb-0 sm:mr-2">
            <MdOutlineWorkOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"/>
            <select 
              className="w-full p-2 pl-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#7A121A]"
              value={selectedJobCategory}
              onChange={(e) => setSelectedJobCategory(e.target.value)}
            >
              <option value="">Todos los Puestos</option>
              {jobCategories.map(category => (
                <option key={category.id} value={category.id}>{category.name}</option>
              ))}
            </select>
          </div>
          <div className="relative flex-1 mb-2 sm:mb-0 sm:mr-2">
            <PiMapPinAreaLight className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"/>
            <select 
              className="w-full p-2 pl-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#7A121A]"
              value={selectedProvinceCategory}
              onChange={(e) => setSelectedProvinceCategory(e.target.value)}
            >
              <option value="">Todas las Provincias</option>
              {provinceCategories.map(category => (
                <option key={category.id} value={category.id}>{category.name}</option>
              ))}
            </select>
          </div>
          <button 
            className="px-4 py-2 bg-[#7A121A] text-white rounded-md hover:bg-[#b91c1c]"
            onClick={handleSearch}
          >
            Buscar empleos
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeroVacantes;
