import React from 'react';
import logoRojo from '../../assets/images/LogoRojo.svg';

const ProductCard = ({ product, onClick, isSelected }) => {
  const getTimeAgo = (date) => {
    const now = new Date();
    const diff = now - new Date(date);
    const minutes = Math.floor(diff / 1000 / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) return `Hace ${years} año${years > 1 ? 's' : ''}`;
    if (months > 0) return `Hace ${months} mes${months > 1 ? 'es' : ''}`;
    if (days > 0) return `Hace ${days} día${days > 1 ? 's' : ''}`;
    if (hours > 0) return `Hace ${hours} hora${hours > 1 ? 's' : ''}`;
    if (minutes > 0) return `Hace ${minutes} minuto${minutes > 1 ? 's' : ''}`;
    return 'Hace unos segundos';
  };

  return (
    <div 
      className={`bg-white rounded-lg overflow-hidden cursor-pointer w-[470px] border ${isSelected ? 'border-[#7A121A] border-2' : 'border-gray-300'} mb-4`} 
      onClick={() => onClick(product)}
    >
      <div className="flex items-center p-4">
        <img src={logoRojo} alt="Logo Synergia" className="w-16 h-16 mr-4"/>
        <div>
          <h2 className="text-xl font-bold text-[#7A121A]">{product.name}</h2>
          <p className="text-gray-600">{product.price} {product.currency}</p>
          <p className="text-sm text-gray-500">{getTimeAgo(product.date_created)}</p>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
