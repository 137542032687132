import React from 'react';
import { RiShareForwardFill, RiHeartFill, RiEyeFill } from "react-icons/ri";

const HeaderProducto = ({ product }) => {
  const getTimeAgo = (date) => {
    const now = new Date();
    const diff = now - new Date(date);
    const minutes = Math.floor(diff / 1000 / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) return `Hace ${years} año${years > 1 ? 's' : ''}`;
    if (months > 0) return `Hace ${months} mes${months > 1 ? 'es' : ''}`;
    if (days > 0) return `Hace ${days} día${days > 1 ? 's' : ''}`;
    if (hours > 0) return `Hace ${hours} hora${hours > 1 ? 's' : ''}`;
    if (minutes > 0) return `Hace ${minutes} minuto${minutes > 1 ? 's' : ''}`;
    return 'Hace unos segundos';
  };

  return (
    <div className="flex justify-between items-center pb-4 border-b border-[#7A121A]">
      <div>
        <h3 className="text-xl font-bold text-[#7A121A]">{product.name}</h3>
        <p className="text-sm text-gray-500">{getTimeAgo(product.date_created)}</p>
      </div>
      <div className="flex space-x-2">
        <button className="bg-[#7A121A] text-white px-4 py-2 rounded-md">Postular</button>
        <RiHeartFill className="h-6 w-6 text-[#7A121A] cursor-pointer" />
        <RiShareForwardFill className="h-6 w-6 text-[#7A121A] cursor-pointer" />
        <RiEyeFill className="h-6 w-6 text-[#7A121A] cursor-pointer" />
      </div>
    </div>
  );
};

export default HeaderProducto;
