import React from 'react';

const ObjetivoPuesto = ({ objetivoPuesto }) => (
  objetivoPuesto && (
    <div className="mb-4">
      <h4 className="font-bold text-[#7A121A]">Objetivo del Puesto:</h4>
      <div dangerouslySetInnerHTML={{ __html: objetivoPuesto }}></div>
    </div>
  )
);

export default ObjetivoPuesto;
