import React from 'react';
import logoRojo from '../../assets/images/LogoRojo.svg';

const VisionMision = () => {
  return (
    <div className="bg-white py-16 sm:py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 text-center md:text-left">
          <div className="col-span-1 flex flex-col items-center md:items-start justify-center">
            <img src={logoRojo} alt="Logo" className="h-6 mb-2" />
            <h3 className="text-2xl font-bold text-[#7A121A] mb-4">VISIÓN</h3>
            <p className="text-lg text-gray-500 text-justify">
              Ser reconocidos como una empresa líder en la gestión integral de servicios empresariales.
            </p>
          </div>
          <div className="col-span-1 flex flex-col items-center md:items-start justify-center">
            <img src={logoRojo} alt="Logo" className="h-6 mb-2" />
            <h3 className="text-2xl font-bold text-[#7A121A] mb-4">MISIÓN</h3>
            <p className="text-lg text-gray-500 text-justify">
              Contribuir al desarrollo de nuestros clientes, con una cultura de excelencia en donde prevalezca la calidez humana, la calidad en el servicio y una alta capacidad resolutiva en la atención.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisionMision;
